import * as React from 'react';
import {Edit, SimpleForm} from 'react-admin';
import OrgMenuForm from "../forms/orgMenuForm";


export const orgMenuEdit = (props: any) => (
    <Edit {...props} redirect="list">
        <SimpleForm>
            <OrgMenuForm/>
        </SimpleForm>
    </Edit>

);

export default orgMenuEdit