import {Create, SimpleForm} from "react-admin";
import * as React from "react";
import OrganizationForm from "../forms/organizationForm";

export const organizationCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <OrganizationForm/>
        </SimpleForm>
    </Create>

);