import * as React from 'react';
import {Create, SimpleForm} from 'react-admin';
import TableForm from "../forms/tableForm";


export const tableCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TableForm/>
        </SimpleForm>
    </Create>
);

export default tableCreate