import * as React from 'react';
import {Create, SimpleForm} from 'react-admin';
import OrgMenuForm from "../forms/orgMenuForm";


export const orgMenuCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <OrgMenuForm/>
        </SimpleForm>
    </Create>
);

export default orgMenuCreate