import * as React from 'react';
import {NumberInput, required} from 'react-admin';

interface NumberInputWithoutWheelProps {
    source: string;
    name: string;
    label: string;
    sx?: React.CSSProperties
}

const NumberInputWithoutWheel: React.FC<NumberInputWithoutWheelProps> = (
    {
        source,
        name,
        label,
        sx
    }
) => {
    const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        event.preventDefault();
    };

    return (
        <NumberInput
            source={source}
            name={name}
            label={label}
            sx={sx}
            validate={[required()]}
            onWheel={handleWheel}
        />
    );
};

export default NumberInputWithoutWheel;
