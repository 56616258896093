// dataProvider.ts
import axios from "axios";
import {DataProvider, useStore} from "react-admin";

const apiUrl = process.env.REACT_APP_API_URL; // Базовый URL API


const customDataProvider: DataProvider = {
    getList: async (resource, params) => {

        if (params?.meta?.orgId) {
            params.filter = {
                orgId: params.meta.orgId
            };
        }

        const query = {
            ...params,
            filter: JSON.stringify(params.filter),
        }

        const url = `${apiUrl}/${resource}/get_list`;
        const response = await axios.get(url, {params: query});

        return {
            data: response.data.items,
            total: response.data.total
        }
    },

    // Добавьте другие методы в зависимости от ваших потребностей
    getOne: async (resource, params) => {

        let url = `${apiUrl}/${resource}/get_item/${params.id}`;

        if (['dish_categories', 'dishes'].includes(resource)) {
            url = `${apiUrl}/${resource}/get_item/${params.id}/${params.meta.orgId}`;
        }

        const response = await axios.get(url,);

        if (['organizations', 'dishes'].includes(resource)) {

            const base64String = response.data.photoBase64;
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: "image/png"});

            const file = new File([blob], response.data.fileName, {type: "image/png"});

            response.data.photoPath = {
                rawFile: file,
                src: response.data.photoPath,
                title: response.data.fileName
            }
        }

        return {
            data: response.data,
        };
    },

    getMany: async (resource, params) => {
        const url = `${apiUrl}/choices/${resource}/${params.ids[0]}/`;
        const response = await axios.get(url);
        return {
            data: response.data,
        };
    },

    getManyReference: (resource, params) => {
        return Promise.resolve({data: []});
    },

    update: async (resource, params) => {
        // Реализуйте этот метод, чтобы обновлять элемент
        let url = `${apiUrl}/${resource}/update/${params.id}`;

        if (['dish_categories', 'dishes'].includes(resource)) {
            url = `${apiUrl}/${resource}/update/${params.id}/${params.meta.orgId}`;
        }

        const response = await axios.put(url, params.data)

        if (response.data && response.data.id) {
            console.log('return after update ', response.data)
            return {
                data: response.data,
            };
        } else {
            throw new Error("Invalid response format for 'update'");
        }

    },

    updateMany: (resource, params) => {
        return Promise.resolve({data: []});
    },

    create: async (resource, params) => {
        // Реализуйте этот метод, чтобы создавать новый элемент
        let url = `${apiUrl}/${resource}/create`;

        console.log(params)

        if (['dish_categories', 'dishes'].includes(resource)) {
            url = `${apiUrl}/${resource}/create/${params.meta.orgId}`;
        }

        const response = await axios.post(url, params.data);
        console.log(response)
        if (response.data && response.data.id) {
            console.log('return after create ', response.data)
            return {
                data: response.data,
            };
        } else {
            throw new Error("Invalid response format for 'create'");
        }
    },

    delete: async (resource, params) => {
        const url = `${apiUrl}/${resource}/delete/${params.id}`;
        const response = await axios.delete(url);
        return {
            data: response.data,
        };
    },

    deleteMany: async (resource, params) => {
        const url = `${apiUrl}/${resource}/delete_many`;
        const response = await axios.post(url, params.ids);

        return {
            data: response.data,
        };
    }


};

const convertFileToBase64 = (file: { rawFile: Blob; }) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });

const customDataProviderWithUploadImage = (customDataProvider: any) => ({
    ...customDataProvider,


    update: (resource: string, params: {
        id: any;
        data: { photoPath: any; title: any; };
    }) => {

        if (!(['organizations', 'dishes'].includes(resource)) || !params.data.photoPath) {
            return customDataProvider.update(resource, params);
        }

        const newPictures: any = params.data.photoPath
        let pictureFromBase64: { src: any, title: any } = {
            src: "",
            title: ""
        };

        if (newPictures.rawFile instanceof File) {
            return convertFileToBase64(params.data.photoPath).then(image =>
                customDataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        photoPath: {
                            src: image,
                            title: params.data.photoPath.title
                        }
                    }
                }))

        } else {
            return Promise.reject('Error: Not a file...');
        }
    },
    create: (resource: string, params: { data: { photoPath: any; title: any; }; }) => {

        if (!(['organizations', 'dishes'].includes(resource)) || !params.data.photoPath) {
            return customDataProvider.create(resource, params);
        }

        const newPictures: any = params.data.photoPath

        let pictureFromBase64: { src: any, title: any } = {
            src: "",
            title: ""
        };

        if (newPictures.rawFile instanceof File) {
            return convertFileToBase64(params.data.photoPath).then(image =>
                customDataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        photoPath: {
                            src: image,
                            title: params.data.photoPath.title
                        }
                    }
                }))

        } else {
            return Promise.reject('Error: Not a file...');
        }
    },


});

const uploadCapableDataProvider = customDataProviderWithUploadImage(customDataProvider);

export default uploadCapableDataProvider;
