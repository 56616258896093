import {BooleanInput, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';
import * as React from 'react';


const OrgMenuForm: React.FC = () => {
    return (
        <div>
            <TextInput
                source="name"
                name="name"
                label="Название"
                validate={[required()]}
                // sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <ReferenceInput source="orgId" reference="organizations" label="Организация" name="organizations">
                <SelectInput
                    label="Организация"
                    source="orgId"
                    optionText="name"
                />
            </ReferenceInput>
        </div>
    );
};

export default OrgMenuForm;
