import * as React from 'react';
import {Edit, SimpleForm} from 'react-admin';
import TableForm from "../forms/tableForm";


export const tableEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TableForm/>
        </SimpleForm>
    </Edit>

);

export default tableEdit