import {BooleanInput, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';
import * as React from 'react';


const TableForm: React.FC = () => {
    return (
        <div>
            <TextInput
                source="number"
                name="number"
                label="Номер"
                validate={[required()]}
                // sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="comment"
                name="comment"
                label="Описание"
                validate={[required()]}
                // sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <ReferenceInput source="hallId" reference="halls" label="Зал" name="halls">
                <SelectInput
                    label="Зал"
                    source="hallId"
                    optionText="name"
                />
            </ReferenceInput>
            <BooleanInput
                size={"small"}
                source="status"
                name="status"
                label="Статус"
                validate={[required()]}
                // sx={{marginLeft: '10px', fontSize: '5', marginTop: '20px'}}
            />
            <BooleanInput
                size={"small"}
                source="isFree"
                name="isFree"
                label="Свободен"
                validate={[required()]}
                // sx={{marginLeft: '10px', fontSize: '5', marginTop: '20px'}}
            />
        </div>
    );
};

export default TableForm;
