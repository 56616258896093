import * as React from 'react';
import {useLocation} from "react-router-dom";
import {SimpleForm, useEditController, useRedirect} from 'react-admin';
import DishCategoriesForm from "../forms/dishCategoriesForm";
import handleClickCustom from "../../systemHelpers/CustomHandleClick";


export const DishCategoriesEdit = () => {

    const {pathname} = useLocation();
    const [id, orgId] = pathname.split('/').slice(-2);
    const redirect = useRedirect();

    const {record, isLoading} = useEditController(
        {
            resource: 'dish_categories',
            id,
            queryOptions: {
                meta: {
                    "orgId": orgId
                }
            }
        });

    if (isLoading) return null;

    const handleClick = (data: any) => {
        return handleClickCustom(data, 'dish_categories', orgId, 'edit', record)
    }

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <DishCategoriesForm/>
            </SimpleForm>
        </div>
    );
};

export default DishCategoriesEdit
