import * as React from "react";
import {
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    ExportButton,
    FilterButton,
    List, ReferenceInput,
    TextField, TextInput,
    TopToolbar
} from 'react-admin';
import BooleanFieldWithContext from "../elements/BooleanFieldWithContext";

const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const postFilters = [
    <TextInput name="name" label="Имя" source="name" defaultValue="" />,
    <TextInput name="surname" label="Фамилия" source="surname" defaultValue="" />,
    <TextInput name="patronymic" label="Отчетство" source="patronymic" defaultValue="" />,
    <TextInput name="login" label="Пользователь" source="login" defaultValue="" />,
    <ReferenceInput source="orgId" name="Организация" reference="organizations" defaultValue="" />
];

export const userList = (
    <List
    actions={<PostListActions/>}
    filters={postFilters}>
        <Datagrid>
            <TextField source="name" label="Имя"/>
            <TextField source="surname" label="Фамилия"/>
            <TextField source="patronymic" label="Отчество"/>
            <TextField source="login" label="Login"/>
            <BooleanFieldWithContext source="status" label="Статус"/>
            <TextField source="userType" label="Тип пользователя"/>
            <TextField source="orgName" label="Организация"/>
            <DateField source="dateCreate" label="Дата создания"/>
            <DateField source="dateLogin" label="Дата последней авторизации"/>
            <EditButton/>
        </Datagrid>
    </List>
);


