import {
    ArrayInput,
    BooleanInput,
    DateInput,
    ImageField,
    ImageInput,
    required,
    SimpleFormIterator,
    TextInput
} from 'react-admin';
import * as React from 'react';
import GenerateMD5DeliveryKeyInput from "../elements/ButtonGeneratrMD5DeliveryKey";


const OrganizationForm: React.FC = () => {

    return (
        <div>
            <TextInput source="name"
                       name="name"
                       label="Название организации"
                       validate={[required()]}
            />
            <TextInput source="address"
                       label="Адрес"
                       validate={[required()]}
                       name="address"
            />
            <DateInput source="datePayment"
                       name="datePayment"
                       label="Дата оплаты"
                       validate={[required()]}
            />
            <TextInput source="taxPercent"
                       name="taxPercent"
                       label="Процент за обслуживание"
                       validate={[required()]}
            />
            <TextInput source="slug"
                       name="slug"
                       label="slug"
            />
            <GenerateMD5DeliveryKeyInput
                source="deliveryKey"
                label="Delivery key"
                onChange={(e) => {
                }}
            />
            <DateInput source="expireDateDelivery"
                       name="expireDateDelivery"
                       label="Срок действия ключа"
            />
            <ArrayInput
                source="contactInfo"
                name="contactInfo"
                label="Информация о контакте"
            >

                <SimpleFormIterator inline>
                    <TextInput
                        source="contactName"
                        label="Имя"
                    />
                    <TextInput
                        source="phone"
                        label="Телефон"
                    />
                    <TextInput
                        source="email"
                        label="Email"
                    />
                    <TextInput
                        source="jobTitle"
                        label="Должность"
                    />
                </SimpleFormIterator>
            </ArrayInput>
            <ImageInput
                /*format={formatLogo}*/
                accept="image/*"
                multiple={false}
                source="photoPath"
                name="photoPath"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>

            <BooleanInput
                size={"small"}
                source="status"
                name="status"
                label="Статус"
                validate={[required()]}
                // sx={{marginRight: '10px', fontSize: '5', marginTop: '20px'}}
            />
        </div>
    );
};

export default OrganizationForm;
