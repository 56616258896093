import {
    CreateButton,
    Datagrid,
    EditButton, ExportButton,
    FilterButton,
    List, ReferenceInput, SearchInput,
    SelectColumnsButton,
    TextField, TextInput,
    TopToolbar
} from "react-admin";

const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const postFilters = [
    <TextInput name="name" label="Название" source="name" defaultValue="" alwaysOn />,
    <ReferenceInput source="orgId" name="Организация" reference="organizations" defaultValue="" alwaysOn />
];
export const MenuList = (
    <List
    actions={<PostListActions />}
    filters={postFilters}>
        <Datagrid>
            <TextField source="name" label="Название"/>
            <TextField source="orgName" label="Организация"/>
            <EditButton/>
        </Datagrid>
    </List>
);