import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField,
    TextInput,
    TopToolbar,
    FunctionField, useStore, ArrayField, ChipField
} from 'react-admin';
import no_image from "../../assets/image/no_image.jpg";
import {CustomEditButton} from "../elements/customEditButton";
import {useLocation, useSearchParams} from "react-router-dom";
import {CustomCreateButton} from "../elements/customCreateButton";
import {Avatar} from "@material-ui/core";
import LanguageIcon from "@mui/icons-material/Language";
import kzFlag from "../../assets/image/kz.png";
import ruFlag from "../../assets/image/ru.png";
import enFlag from "../../assets/image/en.png";

type RecordType = {
    kk?: string;
    ru?: string;
    en?: string;
};

const PostListActions = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filterParams = searchParams.get('filter')

    let objectFilterParams = filterParams ? JSON.parse(filterParams) : {};

    return <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton />
        <CustomCreateButton resource="dishes" orgId={objectFilterParams.orgId}/>
        <ExportButton />
    </TopToolbar>
};

const postFilters = [
    <TextInput name="name" label="Название блюда" source="name" defaultValue="" alwaysOn />,
    <TextInput name="dishCategoryName" label="Категория" source="comment" defaultValue="" />,
    <TextInput name="comment" label="Описание" source="orgName" defaultValue="" alwaysOn />
];

export const dishesList = (
    <List
    actions={<PostListActions />}
    filters={postFilters}>
        <Datagrid>
            <FunctionField label="Название блюда" render={
                (record: { name: RecordType; }) => (
                    <>
                        {record?.name?.kk && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} icon={<LanguageIcon/>} source="name.kk"/>}
                        {record?.name?.ru && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} icon={<LanguageIcon/>} source="name.ru"/>}
                        {record?.name?.en && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} icon={<LanguageIcon/>} source="name.en"/>}
                    </>
                )
            } />
            <FunctionField label="Описание" render={
                (record: { comment: RecordType; }) => (
                    <>
                        {record?.comment?.kk && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} icon={<LanguageIcon/>} source="comment.kk"/>}
                        {record?.comment?.ru && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} icon={<LanguageIcon/>} source="comment.ru"/>}
                        {record?.comment?.en && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} icon={<LanguageIcon/>} source="comment.en"/>}
                    </>
                )
            } />
            <FunctionField label="Категория" render={
                (record: { dishCategoryName: RecordType; }) => (
                    <>
                        {record?.dishCategoryName?.kk && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} icon={<LanguageIcon/>} source="dishCategoryName.kk"/>}
                        {record?.dishCategoryName?.ru && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} icon={<LanguageIcon/>} source="dishCategoryName.ru"/>}
                        {record?.dishCategoryName?.en && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} icon={<LanguageIcon/>} source="dishCategoryName.en"/>}
                    </>
                )
            } />
            <TextField source="weight" label="Вес"/>
            <FunctionField
                label="Изображение"
                render={(record: Record<any, any>) => {
                    if (record.photoPath == 'no_image') {
                        return <img
                            src={no_image}
                            alt="Изображение"
                            style={{
                                maxWidth: 50,
                                maxHeight: 50,
                                objectFit: 'contain'
                            }}
                        />;
                    } else {
                        return <img
                            src={record.photoPath}
                            alt="Изображение"
                            style={{
                                maxWidth: 50,
                                maxHeight: 50,
                                objectFit: 'contain'
                            }}
                        />;
                    }
                }}
            />
            <CustomEditButton resource="dishes"/>
        </Datagrid>
    </List>
);


