import * as React from 'react';
import {SimpleForm, useRedirect} from 'react-admin';
import DishesForm from "../forms/dishesForm";
import {useForm} from 'react-hook-form';
import {useLocation} from "react-router-dom";
import handleClickCustom from "../../systemHelpers/CustomHandleClick";


export const DishesCreate = (props: any) => {

    const {pathname} = useLocation();
    const [id, orgId] = pathname.split('/').slice(-2);
    const redirect = useRedirect();
    const {handleSubmit, formState} = useForm();

    const handleClick = (data: any) => {
        return handleClickCustom(
            data,
            'dishes',
            orgId,
            'create')
    }

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <DishesForm orgId={orgId}/>
            </SimpleForm>
        </div>
    );

};

export default DishesCreate