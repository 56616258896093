import * as React from 'react';
import {PasswordInput, required, SimpleForm, useAuthProvider, useNotify, useRedirect} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import styles from "../../styles/styles.css";


const equalToPassword = (value, allValues) => {
    if (value !== allValues['confirmPassword']) {
        return 'Два пароля должны совпадать';
    }
}

const useStyles = makeStyles(styles);
const ChangePasswordForm = (props) => {
    const authProvider = useAuthProvider()
    const notify = useNotify()
    const redirect = useRedirect();
    const classes = useStyles();

    const handleSubmit = async (values) => {
        // Отправьте данные на сервер FastAPI
        try {
            await authProvider.changePassword({data: values});

        } catch (error) {
            console.log(error)
            notify(`${error}`);
        }
    };

    return (
        <div id="change-password-form" className={classes.changePasswordForm}>
            <SimpleForm {...props} onSubmit={handleSubmit}>
                <PasswordInput
                    label="New Password"
                    source="newPassword"
                    validate={required()}/>
                <PasswordInput
                    label="Confirm Password"
                    source="confirmPassword"
                    validate={equalToPassword}/>
            </SimpleForm>
        </div>
    );
};

export default ChangePasswordForm;
