import * as React from 'react';
import {Edit, SimpleForm} from 'react-admin';
import HallsForm from "../forms/hallsForm";


export const hallsEdit = (props: any) => (
    <Edit {...props} redirect="list">
        <SimpleForm>
            <HallsForm/>
        </SimpleForm>
    </Edit>

);

export default hallsEdit