import * as React from 'react';
import {TextInput} from 'react-admin';
import KeyIcon from '@mui/icons-material/Key';
import TempPasswordInputProps from "../../types/TempPasswordInputInterFace";
import {useFormContext} from "react-hook-form";


const TempPasswordInput: React.FC<TempPasswordInputProps> = (
    {
        source,
        label,
        onChange,
    }) => {

    const {setValue} = useFormContext();


    const generateTempPassword = () => {

        // Здесь вы можете реализовать логику для генерации временного пароля
        const generatedTempPassword = Math.random().toString(36).slice(-8);
        console.log(`Generated temp password: ${generatedTempPassword}`);

        // Обновите значение временного пароля
        setValue(source, generatedTempPassword);

        // Обновите значение поля в соответствии со сгенерированным паролем
        onChange(generatedTempPassword);

    };

    return (
        <div>
            <TextInput
                source={source}
                label={label}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <button type="button" onClick={generateTempPassword}>
                            <KeyIcon/>
                        </button>
                    ),
                }}
            />
        </div>
    );
};

export default TempPasswordInput;
