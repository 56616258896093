// import {Admin, CustomRoutes, houseLightTheme, Resource} from 'react-admin';

import authProvider from "../api/providers/auth/authProvider";
import {userList} from "../componets/grids/DataGridUser";
import {userCreate} from "../componets/create/userCreate";
import {userEdit} from "../componets/edit/userEdit";

import {organizationList} from "../componets/grids/DataGridOrganization";
import {organizationCreate} from "../componets/create/organizationCreate";
import {organizationEdit} from "../componets/edit/organizationEdit";

import UserIcon from "@mui/icons-material/Group";
import BusinessIcon from '@mui/icons-material/Business';
import AppsIcon from '@mui/icons-material/Apps';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import {Dashboard} from "../componets/Dashboard";
import dataProvider from "../api/providers/data/customDataProvider";
import {hallsList} from "../componets/grids/DataGridHalls";
import hallsEdit from "../componets/edit/hallsEdit";
import ChangePasswordForm from "../componets/forms/ChangePasswordForm";
import dishesEdit from "../componets/edit/dishesEdit";
import dishesCreate from "../componets/create/dishesCreate";
import {dishesList} from "../componets/grids/DataGridDishes";

import MenuIcon from '@mui/icons-material/Menu';
import {dishCategoriesList} from "../componets/grids/DataGridDishCategories";
import dishCategoriesEdit from "../componets/edit/dishCategoriesEdit";
import dishCategoriesCreate from "../componets/create/dishCategoriesCreate";

import hallsCreate from "../componets/create/hallsCreate";
import tableCreate from "../componets/create/tableCreate";
import tableEdit from "../componets/edit/tableEdit";
import {tablesList} from "../componets/grids/DataGridTable";
import Layout from "../layout/Layout";
import {Admin, CustomRoutes, houseDarkTheme, houseLightTheme, Resource} from "react-admin";
import { Route } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import {MenuList} from "../componets/grids/DataGridOrgMenu";
import orgMenuCreate from "../componets/create/orgMenuCreate";
import orgMenuEdit from "../componets/edit/orgMenuEdit";

const myTheme = {
    ...houseDarkTheme,
    palette: {
        secondary: {
            main: '#11cb5f',
        },
        primary: {
            main: '#11cb5f',
        },
    },
};

const App = () =>
    (
        <Admin dataProvider={dataProvider}
               basename="Главная страница"
               layout={Layout}
               authProvider={authProvider}
               theme={myTheme}
               dashboard={Dashboard}
        >

            <Resource name="users"
                      list={userList}
                      create={userCreate}
                      edit={userEdit}
                      recordRepresentation="name"
                      icon={UserIcon}
            />
            <Resource name="organizations"
                      list={organizationList}
                      create={organizationCreate}
                      edit={organizationEdit}
                      recordRepresentation="name"
                      icon={BusinessIcon}
            />
            <Resource name="halls"
                      list={hallsList}
                      create={hallsCreate}
                      edit={hallsEdit}
                      recordRepresentation="name"
                      icon={AppsIcon}
            />
            <Resource name="tables"
                      list={tablesList}
                      create={tableCreate}
                      edit={tableEdit}
                      recordRepresentation="name"
                      icon={TableRestaurantIcon}
            />
            <Resource name="dish_categories"
                      list={dishCategoriesList}
                      create={dishCategoriesCreate}
                      edit={dishCategoriesEdit}
                      recordRepresentation="name"
                      icon={MenuIcon}
            />
            <Resource name="menu"
                      list={MenuList}
                      create={orgMenuCreate}
                      edit={orgMenuEdit}
                      recordRepresentation="name"
            />
            <Resource name="dishes"
                      list={dishesList}
                      create={dishesCreate}
                      edit={dishesEdit}
                      recordRepresentation="name"
                      icon={RestaurantIcon}
            />

            <CustomRoutes noLayout>
                <Route path="/change-password" element={<ChangePasswordForm/>}/>
            </CustomRoutes>
        </Admin>
    );

export default App;