import {
    ArrayField,
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    TextField,
    TextInput,
    TopToolbar
} from "react-admin";
import BooleanFieldWithContext from "../elements/BooleanFieldWithContext";

import no_image from "../../assets/image/no_image.jpg";
import * as React from "react";

const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);

const postFilters = [
    <TextInput name="name" label="Название организации" source="name" defaultValue="" alwaysOn/>
];

export const organizationList = (
    <List
        actions={<PostListActions/>}
        filters={postFilters}>
        <Datagrid>
            <TextField source="name" label="Название организации"/>
            <TextField source="address" label="Адрес"/>
            <DateField source="datePayment" label="Дата оплаты"/>
            <ArrayField source="contactInfo" label="Информация о контакте">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="contactName" label="ФИО"/>
                    <TextField source="phone" label="Телефон"/>
                    <TextField source="email" label="Email"/>
                    <TextField source="jobTitle" label="Должность"/>
                </Datagrid>
            </ArrayField>
            <TextField source="taxPercent" label="Процент за обслуживание"/>
            <TextField source="slug" label="Slug"/>
            <TextField source="deliveryKey" label="Delivery key"/>
            <DateField source="expireDateDelivery" label="Срок действия ключа"/>
            <BooleanFieldWithContext source="status" label="Статус"/>
            <FunctionField
                label="Логотип"
                render={(record: Record<any, any>) => {
                    if (record.photoPath == 'no_image') {
                        return <img
                            src={no_image}
                            alt="Логотип"
                            style={{
                                maxWidth: 50,
                                maxHeight: 50,
                                objectFit: 'contain'
                            }}
                        />;
                    } else {
                        return <img
                            src={record.photoPath}
                            alt="Логотип"
                            style={{
                                maxWidth: 50,
                                maxHeight: 50,
                                objectFit: 'contain'
                            }}
                        />;
                    }
                }}
            />
            <EditButton/>
        </Datagrid>
    </List>
);