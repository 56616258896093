import * as React from 'react';
import {Edit, SimpleForm} from 'react-admin';
import OrganizationForm from "../forms/organizationForm";


export const organizationEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <OrganizationForm/>
        </SimpleForm>
    </Edit>

);
