import {ArrayInput, BooleanInput, required, SimpleFormIterator, TextInput, TranslatableInputs} from 'react-admin';

import * as React from 'react';
import NumberInputWithoutWheel from "../elements/NumberImputWithoutWheel";


const DishCategoriesForm: React.FC = () => {

    return (
        <div>
            <TranslatableInputs locales={['en', 'kk', 'ru']}>
                <TextInput source="name" label="Наименование"/>
            </TranslatableInputs>
            <NumberInputWithoutWheel
                source="orderIndex"
                name="orderIndex"
                label="Номер сортировки"
            />
            <BooleanInput
                size={"small"}
                source="status"
                name="status"
                label="Статус"
                validate={[required()]}
            />
        </div>
    );
};

export default DishCategoriesForm;
