import {BooleanInput, DateInput, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';
import * as React from 'react';


const HallsForm: React.FC = () => {
    return (
        <div>
            <TextInput
                source="name"
                name="name"
                label="Наименование зала"
                validate={[required()]}
                // sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="comment"
                name="comment"
                label="Описание"
                validate={[required()]}
                // sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <ReferenceInput source="orgId" resource="halls" reference="organizations" label="Организация" name="organizations">
                <SelectInput
                    label="Организация"
                    source="orgId"
                    optionText="name"
                />
            </ReferenceInput>
            <BooleanInput
                size={"small"}
                source="status"
                name="status"
                label="Статус"
                validate={[required()]}
                // sx={{marginLeft: '10px', fontSize: '5', marginTop: '20px'}}
            />
        </div>
    );
};

export default HallsForm;
