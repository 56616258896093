import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List, ReferenceInput,
    TextField,
    TextInput,
    TopToolbar
} from "react-admin";
import * as React from "react";

const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const postFilters = [
    <TextInput name="number" label="Номер стола" source="name" defaultValue="" alwaysOn />,
    <ReferenceInput source="hallId" name="Зал" reference="halls" defaultValue="" alwaysOn />,
    <TextInput name="comment" label="Комментарий" source="comment" defaultValue="" />
];

export const tablesList = (
    <List
    actions={<PostListActions />}
    filters={postFilters}>
        <Datagrid>
            <TextField source="number" label="Номер стола"/>
            <TextField source="comment" label="Описание"/>
            <TextField source="hallName" label="Зал"/>]
            <EditButton/>
        </Datagrid>
    </List>
);