import * as React from "react";
import {
    ArrayField, ChipField,
    CreateButton,
    Datagrid,
    ExportButton,
    FilterButton, FunctionField,
    List,
    TextField,
    TextInput,
    TopToolbar, useStore,
} from 'react-admin';
import BooleanFieldWithContext from "../elements/BooleanFieldWithContext";
import {CustomEditButton} from "../elements/customEditButton";
import {CustomCreateButton} from "../elements/customCreateButton";
import {useSearchParams} from "react-router-dom";
import LanguageIcon from '@mui/icons-material/Language';
import {Avatar} from "@material-ui/core";
import ruFlag from "../../assets/image/ru.png";
import enFlag from "../../assets/image/en.png";
import kzFlag from "../../assets/image/kz.png";

const PostListActions = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filterParams = searchParams.get('filter')

    let objectFilterParams = filterParams ? JSON.parse(filterParams) : {};

    return <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CustomCreateButton resource="dish_categories" orgId={objectFilterParams.orgId}/>
        <ExportButton/>
    </TopToolbar>
};

const postFilters = [
    <TextInput name="name" label="Название категории" source="name" defaultValue="" alwaysOn/>,
    <TextInput name="orgName" label="Название организации" source="comment" defaultValue=""/>,
    <TextInput name="orderIndex" label="Номер сортировки" source="orgName" defaultValue="" alwaysOn/>
];
export const dishCategoriesList = (
    <List
        actions={<PostListActions/>}
        filters={postFilters}>
        <Datagrid>
            <FunctionField label="Наименование" render={
                (record: { name: any; }) => (
                    <>
                        {record?.name?.kk && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} icon={<LanguageIcon/>} source="name.kk"/>}
                        {record?.name?.ru && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} icon={<LanguageIcon/>} source="name.ru"/>}
                        {record?.name?.en && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} icon={<LanguageIcon/>} source="name.en"/>}
                    </>
                )
            } />
            <TextField source="orderIndex" label="Номер сортировки"/>
            <BooleanFieldWithContext source="status" label="Статус"/>
            <CustomEditButton resource="dish_categories"/>
        </Datagrid>
    </List>
);


