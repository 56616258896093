import {
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    TranslatableInputs
} from 'react-admin';
import * as React from 'react';
import {InputAdornment} from "@material-ui/core";


interface DishesFormProps {
    orgId: string;  // Замените на тип вашего orgId
}

const DishesForm: React.FC<DishesFormProps> = ({ orgId }) => {

    return (
        <div>
            <TranslatableInputs locales={['en', 'kk', 'ru']}>
                <TextInput source="name" label="Наименование"/>
            </TranslatableInputs>
            <TranslatableInputs locales={['en', 'kk', 'ru']}>
                <TextInput source="comment" label="Наименование"/>
            </TranslatableInputs>
            <ReferenceInput
                source="currencyId"
                reference="currency"
                label="Валюта"
            >
                <SelectInput
                    label="Валюта"
                    source="currencyId"
                    optionText="name"
                />
            </ReferenceInput>
            <NumberInput
                source="price"
                label="Цена"
                validate={[required()]}
                name="price"
                // InputProps={{
                //     startAdornment: (
                //         <InputAdornment position="start">€</InputAdornment>
                //     ),
                // }}
                // helperText={false}
                // sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <ReferenceInput
                source="unitId"
                reference="unit"
                label="Ед. измерения"
            >
                <SelectInput
                    label="Ед. измерения"
                    source="unitId"
                    optionText="name"
                />
            </ReferenceInput>
            <TextInput source="weight"
                       label="Вес"
                       validate={[required()]}
                       name="weight"
                       // sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <ReferenceInput
                source="dishCategoryId"
                reference="dish_categories"
                label="Категория"
                queryOptions={{ meta: { orgId: orgId } }}
            >
                <SelectInput
                    label="Категория"
                    source="dishCategoryId"
                    optionText="name.ru"
                />
            </ReferenceInput>
            <ReferenceInput
                source="menuId"
                reference="menu"
                label="Категория"
                name="menu"
                queryOptions={{ meta: { orgId: orgId } }}
            >
                <SelectInput
                    label="Меню"
                    source="menuId"
                    optionText="name"
                />
            </ReferenceInput>
            <ImageInput
                accept="image/*"
                multiple={false}
                source="photoPath"
                name="photoPath"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>

        </div>
    );
};

export default DishesForm;
