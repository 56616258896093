import {BooleanInput, DateInput, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';
import * as React from 'react';
import TempPasswordInput from "../elements/ButtonGeneratedTempPassword";


const FieldGroup = ({label, children, style}: {
    label: string;
    children: React.ReactNode,
    style?: React.CSSProperties
}) => (
    <div style={style}>
        <h3>{label}</h3>
        {children}
    </div>
);

const UserForm: React.FC = () => {
    return (
        <div>
            <FieldGroup label="Персональные данные" style={{width: '35%'}}>
                <TextInput
                    source="name"
                    name="name"
                    label="Имя"
                    validate={[required()]}
                    // sx={{marginRight: '10px', fontSize: '10px'}}
                />
                <TextInput
                    source="surname"
                    name="surname"
                    label="Фамилия"
                    validate={[required()]}
                    // sx={{marginRight: '10px', fontSize: '10px'}}
                />
                <TextInput
                    source="patronymic"
                    name="patronymic"
                    label="Отчество"

                    // sx={{marginRight: '10px', fontSize: '10px'}}
                />
                <TextInput
                    source="login"
                    name="login"
                    label="Логин"

                    // sx={{marginRight: '10px', fontSize: '10px'}}
                />

                <TextInput
                    source="phone"
                    name="phone"
                    label="Номер телефона"

                    // sx={{marginRight: '10px', fontSize: '10px'}}
                />
            </FieldGroup>


            <FieldGroup label="Мета данные" style={{width: '25%'}}>
                <SelectInput
                    source="userType"
                    size={"small"}
                    name="userType"
                    label="Тип пользователя"
                    validate={[required()]}
                    sx={{marginRight: '10px', fontSize: '5'}}
                    choices={[
                        {id: '1', name: 'Администратор приложения'},
                        {id: '2', name: 'Администратор заведения'},
                        {id: '3', name: 'Повар'},
                        {id: '4', name: 'Официант'},
                    ]}/>

                <ReferenceInput source="orgId" reference="organizations" label="Организация" name="organizations">
                    <SelectInput
                        label="Организация"
                        source="orgId"
                        optionText="name"
                    />
                </ReferenceInput>
            </FieldGroup>

            <FieldGroup label="Контрольные даты" style={{width: '15%'}}>
                <DateInput
                    source="dateCreate"
                    name="dateCreate"
                    label="Дата создания"
                    // sx={{marginRight: '10px', fontSize: '10px'}}
                />
                <DateInput
                    source="dateogin"
                    name="dateLogin"
                    label="Дата последней авторизации"
                    // sx={{marginRight: '10px', fontSize: '10px'}}
                />
            </FieldGroup>
            <FieldGroup label="Дополнительные данные" style={{width: '25%'}}>
                <TempPasswordInput
                    source="tempPassword"
                    label="Временный пароль"
                    onChange={(e) => {
                    }}
                />

                <BooleanInput
                    size={"small"}
                    source="status"
                    name="status"
                    label="Статус"
                    validate={[required()]}
                    // sx={{marginRight: '10px', fontSize: '5', marginTop: '20px'}}
                />
            </FieldGroup>


        </div>
    );
};

export default UserForm;
