import * as React from 'react';
import {TextInput} from 'react-admin';
import KeyIcon from '@mui/icons-material/Key';
import MD5InputProps from "../../types/MD5InputInterFace";
import {useFormContext} from "react-hook-form";


const GenerateMD5DeliveryKeyInput: React.FC<MD5InputProps> = (
    {
        source,
        label,
        onChange,
    }) => {

    const {setValue} = useFormContext();


    const generateMD5 = () => {

        // Здесь вы можете реализовать логику для генерации deliveryKey
        const generatedMD5 = generateRandomMD5();
        console.log(`Generated temp password: ${generatedMD5}`);

        // Обновите значение временного пароля
        setValue(source, generatedMD5);

        // Обновите значение поля в соответствии со сгенерированным паролем
        onChange(generatedMD5);

    };

    return (
        <div>
            <TextInput
                source={source}
                label={label}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <button type="button" onClick={generateMD5}>
                            <KeyIcon/>
                        </button>
                    ),
                }}
            />
        </div>
    );
};

export default GenerateMD5DeliveryKeyInput;


function generateRandomMD5() {
    const crypto = require('crypto-js');
    const randomString = Math.random().toString(36).substring(2, 12); // Генерируем случайную строку
    const md5Hash = crypto.MD5(randomString).toString().toUpperCase(); // Вычисляем MD5 хэш
    return md5Hash.substring(0, 10); // Возвращаем первые 10 символов хэша
}


