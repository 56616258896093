import {useRecordContext} from "react-admin";
import * as React from "react";
import { CustomButton } from "../../styles/customButtons";
import EditIcon from '@mui/icons-material/Edit';


export const CustomEditButton = (props: any) => {
    const record = useRecordContext();
    return <CustomButton href={`#/${props.resource}/${record.id}/${record.orgId}`}><EditIcon></EditIcon> Edit</CustomButton>
};
