import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';

import {MenuItemLink, MenuProps, useSidebarState} from 'react-admin';

import SubMenu from './SubMenu';
import MenuIcon from "@mui/icons-material/Menu";
import BusinessIcon from "@mui/icons-material/Business";
import UserIcon from "@mui/icons-material/Group";
import AppsIcon from "@mui/icons-material/Apps";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import axios from "axios";
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import MenuBookIcon from '@mui/icons-material/MenuBook';

type MenuName = 'OrgCategories' | 'OrgDishes' | 'OrgMenu';

const apiUrl = process.env.REACT_APP_API_URL; // Базовый URL API

interface Category {
    id: string;
    name: string;
}

const Menu = ({dense = false}: MenuProps) => {
    const [state, setState] = useState({
        OrgCategories: false,
        OrgDishes: false,
        OrgMenu: false
    });
    // const translate = useTranslate();
    const [open] = useSidebarState();

    const [categories, setCategories] = useState([]);

    const handleToggle = (menu: MenuName) => {
        setState(state => ({...state, [menu]: !state[menu]}));

        const fetchCategories = async () => {
            const url = `${apiUrl}/organizations/get_list`;
            try {
                const response = await axios.get(url);
                setCategories(response.data.items);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {/*<DashboardMenuItem />*/}
            <MenuItemLink
                to="/organizations"
                state={{_scrollToTop: true}}
                primaryText='Организации'
                leftIcon={<BusinessIcon/>}
                dense={dense}
            />
            <MenuItemLink
                to="/users"
                state={{_scrollToTop: true}}
                primaryText='Пользователи'
                leftIcon={<UserIcon/>}
                dense={dense}
            />
            <MenuItemLink
                to="/halls"
                state={{_scrollToTop: true}}
                primaryText='Залы'
                leftIcon={<AppsIcon/>}
                dense={dense}
            />
            <MenuItemLink
                to="/tables"
                state={{_scrollToTop: true}}
                primaryText='Столы'
                leftIcon={<RestaurantIcon/>}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('OrgCategories')}
                isOpen={state.OrgCategories}
                name="Категории блюд"
                dense={dense}
                icon={<MenuIcon/>}
            >
                {
                    Array.isArray(categories) && categories.map(
                        (category: Category) => (
                            <MenuItemLink
                                key={category.id}
                                to={`/dish_categories?filter={"orgId":"${category.id}"}`}
                                state={{_scrollToTop: true}}
                                primaryText={category.name}
                                leftIcon={<HorizontalRuleIcon/>}
                                dense={dense}
                            />
                        )
                    )
                }
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('OrgMenu')}
                isOpen={state.OrgMenu}
                name="Меню"
                dense={dense}
                icon={<MenuBookIcon/>}
            >
                {
                    Array.isArray(categories) && categories.map(
                        (category: Category) => (
                            <MenuItemLink
                                key={category.id}
                                to={`/menu?filter={"orgId":"${category.id}"}`}
                                state={{_scrollToTop: true}}
                                primaryText={category.name}
                                leftIcon={<HorizontalRuleIcon/>}
                                dense={dense}
                            />
                        )
                    )
                }
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('OrgDishes')}
                isOpen={state.OrgDishes}
                name="Блюда"
                dense={dense}
                icon={<DinnerDiningIcon/>}
            >
                {
                    Array.isArray(categories) && categories.map(
                        (category: Category) => (
                            <MenuItemLink
                                key={category.id}
                                to={`/dishes?filter={"orgId":"${category.id}"}`}
                                state={{_scrollToTop: true}}
                                primaryText={category.name}
                                leftIcon={<HorizontalRuleIcon/>}
                                dense={dense}
                            />
                        )
                    )
                }
            </SubMenu>
        </Box>
    );
};

export default Menu;
