import {Card, CardContent, CardHeader} from "@mui/material";


export const Dashboard = () => (
    <Card sx={{
        marginTop: '50px',
        textAlign: 'left'
    }}
          variant="outlined">
        <CardHeader title="Добро пожаловать в систему EasyMealAdmin"/>
        <CardContent>
            В главном меню Администратор приложения видит информацию о количестве подключённых к программе организациях.
            Статус их оплаты. Администратор приложения должен иметь возможность администрировать подключённые к
            приложению организации.
            Иметь возможность добавлять организации, изменять, активировать, деактивировать и удалять.
            К каждой организации администратор должен добавить администратора организации, который в свою очередь будет
            добавлять стафф и прочие функции администратора заведения.
            Администратор приложения настраивает количество залов, столов в организации.
            Администратор приложения может войти в другие 2 приложения, под своей учетной записью, под любой
            организацией и ролью.
        </CardContent>
    </Card>
);